import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import ReportCompleteSnackbar from "../components/Notifications/FabricRequest";
import { useSnackbar } from "notistack";

import { Box, Stack } from "@mui/material";

import { Navbar, StickyFooter, UserNavbar } from "../components";
import { useAuth } from "../contexts/AuthContext";

const AppLayout = () => {
  const [isLoggedin, setIsLoggedin] = useState(false);
  const { auth, getAccountInfo, getFilters, globalFilters } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getFilters();
    console.log(globalFilters);
    auth?.name ? setIsLoggedin(true) : setIsLoggedin(false);
    // setInterval(() => {
    //   enqueueSnackbar("Your report is ready", {
    //     anchorOrigin: { horizontal: "right", vertical: "bottom" },
    //     persist: true,
    //     preventDuplicate:true,
    //     content: (key, message) => (
    //       <ReportCompleteSnackbar id={key} message="test" />
    //     ),
    //   });
    // }, 300000);

    // Condition prevent infinite loop caused by setting auth

    if (auth === null) {
      getAccountInfo();
    }
  }, [auth]);

  const navbarHeight = "124px";

  return isLoggedin ? (
    <Box
      bgcolor={"background.default"}
      color={"text.primary"}
      sx={{ width: "100%", overflowX: "hidden" }}
    >
      {/* Nav Bar */}
      <UserNavbar />
      <Stack direction="column">
        {/* Content */}

        <Box
          flex={4}
          pt={navbarHeight}
        >
          <Outlet />
        </Box>
        {/* Footer */}
        {/* <UserStickyFooter /> */}
        <StickyFooter />
      </Stack>
    </Box>
  ) : (
    <Box bgcolor={"background.default"} color={"text.primary"} sx={{ width: "100%", overflowX: "hidden" }}>
      <Stack direction="column">
        {/* Nav Bar */}
        <Navbar />

        {/* Content */}
        <Box flex={4} pt={navbarHeight}>
          <Outlet />
        </Box>

        <StickyFooter />
      </Stack>
    </Box>
  );
};

export default AppLayout;
