// Design system imports
import { Grid, ThemeProvider, useMediaQuery } from '@mui/material'

// Package imports
import { ConfirmProvider } from 'material-ui-confirm'
import { Outlet } from 'react-router-dom'

// Project imports
import { theme } from '../../theme'

const GridLayout = () => {
  const isWidescreen = useMediaQuery('(min-width:1920px)')

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <Grid container justifyContent="center" width={'100%'} bgcolor={'background.default'}>
          <Grid
            item
            xs={12}
            md={10}
            lg={8}
            xl={isWidescreen ? 6 : 8}
            paddingX={{
              xs: 4,
              md: 0,
            }}
          >
            <Outlet />
          </Grid>
        </Grid>
      </ConfirmProvider>
    </ThemeProvider>
  )
}

export default GridLayout
