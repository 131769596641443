import { Lock, Mail } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Link,
  Stack,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import axiosClient from "../../api/axiosClient";
import { useAuth } from "../../contexts/AuthContext";
import ConfirmationToken from "../Forms/ConfirmationToken";
import ResendConfirmation from "../Forms/ResendConfirmation";
import Button from "../Input/Button";

const LOGIN_URL = "api/auth/login";
const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

type LoginProps = {
  variant: "white" | "black"; // Only allow 'primary' or 'secondary'
};

const Login: React.FC<LoginProps> = ({ variant }) => {
  const { setAuth, getAccountInfo } = useAuth();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, []);

  return (
    <>
      {variant === "black" ? (
        <Button
          variant="contained"
          size="small"
          onClick={handleClickOpen}
          showArrow
        >
          {t("Login.1")}
        </Button>
      ) : (
        <Button
          variant="outlined"
          size="small"
          onClick={handleClickOpen}
          showArrow
        >
          {t("Login.1")}
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle textAlign="center">{t("Login.1")}</DialogTitle>
        <DialogContent>
          <Container
            component="main"
            maxWidth="md"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              maxWidth="sm"
            >
              <Formik
                validationSchema={LoginSchema}
                initialValues={{
                  email: "",
                  password: "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  const data = {
                    password: values.password,
                    email: values.email,
                  };

                  const response = await axiosClient
                    .post(LOGIN_URL, data)
                    .then((res) => {
                      getAccountInfo();
                      localStorage.setItem("auth_token", res.data.token);
                      // enqueueSnackbar(t("Successfully signed in.1"), {
                      //   variant: "success",
                      //   preventDuplicate: true,
                      // });
                      setOpen(false);
                    })
                    .catch((error) => {
                      enqueueSnackbar(error.response.data.error, {
                        variant: "error",
                        preventDuplicate: true,
                      });
                    });
                }}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  isValidating,
                  submitForm,
                }) => (
                  <Form>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        name="email"
                        type="email"
                        label="Email"
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Mail color="primary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        type="password"
                        label={t("Password.1")}
                        name="password"
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock color="primary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      size="large"
                      loading={isSubmitting || isValidating}
                      sx={{
                        marginTop: "1rem",
                        backgroundColor: "primary.main",
                        color: "#fff",
                      }}
                      type="submit"
                    >
                      {t("Sign in.1")}
                    </LoadingButton>
                  </Form>
                )}
              </Formik>
              <Grid
                container
                sx={{ m: 2 }}
              >
                <Grid
                  item
                  xs
                >
                  <Link
                    href="/forgot_password"
                    variant="body2"
                  >
                    {t("Forgot password?.1")}
                  </Link>
                </Grid>
              </Grid>
              <Divider
                variant="middle"
                sx={{
                  color: "#627a70",
                  width: "80%",
                }}
              >
                OR
              </Divider>
              <Stack
                direction="row"
                spacing={4}
                alignItems="center"
                justifyContent="center"
                sx={{ width: "100%", marginTop: "1em" }}
              >
                <ConfirmationToken />
                <ResendConfirmation />
              </Stack>
            </Box>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Login;
