import axiosClient from "./axiosClient";

type GetProductResponse = {
  content: FCProduct[];
  pageable: {
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    unpaged: boolean;
    paged: boolean;
  };
  totalPages: number;
  last: boolean;
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  size: number;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
};

export const getSingleListing = async (listing: {
  organisation: any;
  listingId: any;
}) => {
  const response = await axiosClient.get<FCProduct>(
    `api/organisation/${listing.organisation}/listing/${listing.listingId}`
  );

  return response.data;
};

export const getRelatedListings = async () => {
  const response = await axiosClient.get<GetProductResponse>(
    `api/popular?size=6&page=0`
  );

  return response.data;
};

export const getNewestListings = async (pageParam = 0) => {
  const response = await axiosClient.get<GetProductResponse>(
    `api/newest?size=8&page=${pageParam}`
  );

  return response.data;
};

export const getPopularListings = async (pageParam = 0) => {
  const response = await axiosClient.get<GetProductResponse>(
    `api/popular?size=10&page=${pageParam}`
  );
  return response.data;
};

export const addListing = async (data: { organisation: any; listing: any }) => {
  return await axiosClient.post(
    `api/organisation/${data.organisation}/listing/create`,
    data.listing
  );
};

export const editListing = async (data: {
  organisation: any;
  listing: any;
  listingid: any;
}) => {
  return await axiosClient.put(
    `api/organisation/${data.organisation}/listing/update/${data.listingid}`,
    data.listing
  );
};

export const deleteListing = async (listing: { vendor: any; id: any }) => {
  return await axiosClient.delete(
    `api/organisation/${listing.vendor}/listing/delete/${listing.id}`
  );
};

export const likeListing = async (listing: { vendor: any; id: any }) => {
  return await axiosClient.post(
    `api/organisation/${listing.vendor}/listing/${listing.id}/like`
  );
};

export const unlikeListing = async (listing: { vendor: any; id: any }) => {
  return await axiosClient.post(
    `api/organisation/${listing.vendor}/listing/${listing.id}/unlike`
  );
};

export const getMyProducts = async (
  organisation: any,
  pageParam = 0,
  size = 10
) => {
  const response = await axiosClient.get<GetProductResponse>(
    `api/organisation/${organisation}/listing?page=${pageParam}&size=${size}`
  );
  return response.data;
};

export const getFavoritedListings = async (pageParam = 0, size = 5) => {
  const response = await axiosClient.get<GetProductResponse>(
    `api/user/current/likes?page=${pageParam}&size=${size}`
  );
  return response.data;
};

// TODO:ADD FUNCTION FOR PAGINATED FETCHING LISTING FOR ORGANISATION
