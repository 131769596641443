// Design system imports
import { Box, Container, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

const ClientSection = () => {
  return (
    <Box
      component="section"
      sx={{ px: { xs: 2, lg: 0 } }}
    >
      <Container
        maxWidth="xl"
        sx={{
          padding: "10vh 0",
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{ fontWeight: "bold", lineHeight: 1.6 }}
        >
          Trusted by
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 4,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* TODO: Replace with actual client logos */}
          <Box sx={{ width: "100%", height: "5vh", bgcolor: grey[300] }} />
          <Box sx={{ width: "100%", height: "5vh", bgcolor: grey[300] }} />
          <Box sx={{ width: "100%", height: "5vh", bgcolor: grey[300] }} />
          <Box sx={{ width: "100%", height: "5vh", bgcolor: grey[300] }} />
        </Box>
      </Container>
    </Box>
  );
};

export default ClientSection;
