import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-mui";
import { DropzoneArea } from "mui-file-dropzone";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../api/axiosClient";
import { CreateOrganizationFormSchema } from "../schemas";
import { resizeFile } from "../../utils/Uploading";
import { BorderColor } from "@mui/icons-material";

const CREATE_URL = "/api/organisation/create";
const dropzoneStyle = {
  width: "100%",
  height: "20%",
  border: "1px solid black",
};
interface CreateOrganisationProps {
  variant: "orange" | "black";
}

function CreateOrganisation({ variant }: CreateOrganisationProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [image, setImage] = useState<string | undefined>("");
  const [phone, setPhone] = useState("");
  const [info, setInfo] = useState<MuiTelInputInfo>();
  const handlePhoneChange = (newPhone: string, info: MuiTelInputInfo) => {
    setPhone(newPhone);
    setInfo(info);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const convert2base64 = async (files: any) => {
    try {
      for (let i = 0; i < files.length; i++) {
        const image = await resizeFile(files[i]);
        setImage(image);
      }
    } catch (error) {}
  };

  return (
    <>
      {variant === "orange" ? (
        <>
          <Button
            sx={{ maxWidth: 200, color: "white" }}
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleClickOpen}
          >
            Create Organization
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle textAlign="center">
              Create Your Organization
            </DialogTitle>
            <DialogContent>
              <Container component="main" maxWidth="sm">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Formik
                    // TODO: Validation
                    validationSchema={CreateOrganizationFormSchema}
                    initialValues={{
                      name: "",
                      description: "",
                      email: "",
                      phone: "",
                      countryCode: "",
                      image: {
                        fileContentBase64: "",
                        fileName: "Profile",
                      },
                      terms: false,
                    }}
                    onSubmit={async (
                      values: {
                        name: any;
                        description: any;
                        email: any;
                        phone: any;
                        countryCode: any;
                        image: any;
                      },
                      { setSubmitting }: any
                    ) => {
                      const data = {
                        name: values.name,
                        email: values.email,
                        description: values.description,
                        phone: phone,
                        countryCode: info?.countryCode,
                        image: {
                          fileContentBase64: image,
                          fileName: "Profile",
                        },
                      };

                      axiosClient
                        .post(CREATE_URL, data)
                        .then((res) => {
                          enqueueSnackbar(
                            "Succesfully created your organization",
                            {
                              variant: "success",
                              preventDuplicate: true,
                            }
                          );
                          setOpen(false);
                          enqueueSnackbar("We will refresh your page", {
                            variant: "info",
                            preventDuplicate: true,
                          });
                          setTimeout(() => {
                            navigate(0);
                          }, 1000);
                        })
                        .catch((error) => {
                          enqueueSnackbar(error.response.data.message, {
                            variant: "error",
                            preventDuplicate: true,
                          });
                        });
                    }}
                  >
                    {({
                      values,
                      errors,
                      isSubmitting,
                      isValidating,
                      submitForm,
                    }) => (
                      <Form key={"notiform"}>
                        <FormControl fullWidth>
                          <Field
                            component={TextField}
                            name="name"
                            label="Organization Name"
                            margin="normal"
                            key={"orgname"}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Field
                            component={TextField}
                            name="description"
                            label="Description"
                            margin="normal"
                            multiline
                            rows={4}
                            key={"orgdesc"}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Field
                            component={TextField}
                            name="email"
                            type="email"
                            label="Email"
                            margin="normal"
                            key={"orgmail"}
                          />
                        </FormControl>
                        <MuiTelInput
                          value={phone}
                          onChange={handlePhoneChange}
                          fullWidth
                          label="Phone"
                          required
                          key={"orgnum"}
                        />
                        <Divider sx={{ my: 2 }} />
                        <FormControl fullWidth>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="terms"
                            Label={{
                              label:
                                "By establishing an organization, you agree to sign up for a Bronze membership on The Fabric Connector. This includes unlimited listings, an organization page, a 10% commission on sales, billed quarterly.",
                            }}
                          />
                        </FormControl>

                        <ErrorMessage
                          name="terms"
                          render={(msg) => (
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "#e20" }}
                            >
                              {msg}
                            </Typography>
                          )}
                        />

                        <Divider sx={{ my: 2 }} />

                        <DropzoneArea
                          fileObjects={images}
                          acceptedFiles={["image/*"]}
                          dropzoneText={"Upload a profile picture (.png/jpg)"}
                          maxFileSize={5000000}
                          onChange={(files: any[]) => {
                            if (files[0] !== undefined) {
                              convert2base64(files);
                            }
                          }}
                        />

                        <LoadingButton
                          variant="contained"
                          fullWidth
                          size="large"
                          loading={isSubmitting || isValidating}
                          sx={{
                            marginTop: "1rem",
                            backgroundColor: "secondary.main",
                            color: "#fff",
                          }}
                          type="submit"
                        >
                          Create Organization
                        </LoadingButton>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Container>
            </DialogContent>
          </Dialog>
        </>
      ) : variant === "black" ? (
        <>
          <Button
            sx={{
              maxWidth: 200,
              color: "white",
              backgroundColor: "black",
              borderRadius: "20px",
              paddingLeft: "15px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "grey",
              },
            }}
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleClickOpen}
          >
            Create Organization
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{ sx: { borderRadius: "50px" } }}
          >
            <DialogTitle
              textAlign="center"
              sx={{ marginTop: "10px", fontWeight: "bold" }}
            >
              Create Your Organization
            </DialogTitle>
            <DialogContent>
              <Container component="main" maxWidth="sm">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Formik
                    // TODO: Validation
                    validationSchema={CreateOrganizationFormSchema}
                    initialValues={{
                      name: "",
                      description: "",
                      email: "",
                      phone: "",
                      countryCode: "",
                      image: {
                        fileContentBase64: "",
                        fileName: "Profile",
                      },
                      terms: false,
                    }}
                    onSubmit={async (
                      values: {
                        name: any;
                        description: any;
                        email: any;
                        phone: any;
                        countryCode: any;
                        image: any;
                      },
                      { setSubmitting }: any
                    ) => {
                      const data = {
                        name: values.name,
                        email: values.email,
                        description: values.description,
                        phone: phone,
                        countryCode: info?.countryCode,
                        image: {
                          fileContentBase64: image,
                          fileName: "Profile",
                        },
                      };

                      axiosClient
                        .post(CREATE_URL, data)
                        .then((res) => {
                          enqueueSnackbar(
                            "Succesfully created your organization",
                            {
                              variant: "success",
                              preventDuplicate: true,
                            }
                          );
                          setOpen(false);
                          enqueueSnackbar("We will refresh your page", {
                            variant: "info",
                            preventDuplicate: true,
                          });
                          setTimeout(() => {
                            navigate(0);
                          }, 1000);
                        })
                        .catch((error) => {
                          enqueueSnackbar(error.response.data.message, {
                            variant: "error",
                            preventDuplicate: true,
                          });
                        });
                    }}
                  >
                    {({
                      values,
                      errors,
                      isSubmitting,
                      isValidating,
                      submitForm,
                    }) => (
                      <Form key={"notiform"}>
                        <FormControl fullWidth>
                          <Field
                            component={TextField}
                            name="name"
                            label="Organization Name"
                            margin="normal"
                            key={"orgname"}
                            InputProps={{}}
                            InputLabelProps={{
                              sx: {
                                fontWeight: "bold",
                              },
                            }}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Field
                            component={TextField}
                            name="description"
                            label="Description"
                            margin="normal"
                            multiline
                            rows={4}
                            key={"orgdesc"}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Field
                            component={TextField}
                            name="email"
                            type="email"
                            label="Email"
                            margin="normal"
                            key={"orgmail"}
                          />
                        </FormControl>
                        <MuiTelInput
                          value={phone}
                          onChange={handlePhoneChange}
                          fullWidth
                          label="Phone"
                          required
                          key={"orgnum"}
                        />
                        <Divider sx={{ my: 2 }} />
                        <FormControl fullWidth>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="terms"
                            Label={{
                              label:
                                "By establishing an organization, you agree to sign up for a Bronze membership on The Fabric Connector. This includes unlimited listings, an organization page, a 10% commission on sales, billed quarterly.",
                            }}
                          />
                        </FormControl>

                        <ErrorMessage
                          name="terms"
                          render={(msg) => (
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "#e20" }}
                            >
                              {msg}
                            </Typography>
                          )}
                        />

                        <Divider sx={{ my: 2 }} />

                        <DropzoneArea
                          fileObjects={images}
                          acceptedFiles={["image/*"]}
                          dropzoneText={"Upload a profile picture (.png/jpg)"}
                          maxFileSize={5000000}
                          onChange={(files: any[]) => {
                            if (files[0] !== undefined) {
                              convert2base64(files);
                            }
                          }}
                        />

                        <LoadingButton
                          variant="contained"
                          fullWidth
                          size="large"
                          loading={isSubmitting || isValidating}
                          sx={{
                            marginTop: "1rem",

                            backgroundColor: "black",
                            color: "white",
                            borderRadius: "20px",
                            paddingLeft: "15px",
                            boxShadow: "none",

                            "&:hover": {
                              backgroundColor: "grey",
                            },
                          }}
                          type="submit"
                        >
                          Create Organization
                        </LoadingButton>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Container>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default CreateOrganisation;
