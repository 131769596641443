// Design system imports
import { Box, Grid, Typography, Stack } from '@mui/material'

// Project imports
import Button from '../Input/Button'
import HowDoesItWorkCard from '../Cards/HowDoesItWorkCard'

// Asset imports
import stepOneImage from '../../assets/images/step1.png'
import stepTwoImage from '../../assets/images/step2.png'
import stepThreeImage from '../../assets/images/step3.png'
import stepFourImage from '../../assets/images/step4.png'

const HowDoesItWorkSection = () => {
  return (
    <Box component={'section'} paddingY={8}>
      <Stack direction="row" justifyContent={'space-between'} marginBottom={8}>
        <Typography variant={'h4'} component={'h2'}>
          How does it work?
        </Typography>
        <Button
          variant={'outlined'}
          href={'/how-it-works'}
          showArrow
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          Find out more
        </Button>
      </Stack>
      <Grid container spacing={4} width={'100%'} justifyContent={'center'}>
        {/* Step 1 */}
        <HowDoesItWorkCard
          number={'1'}
          image={stepOneImage}
          text={'Find your fabric, send us a request, or browse online.'}
        />

        {/* Step 2 */}
        <HowDoesItWorkCard
          number={'2'}
          image={stepTwoImage}
          text={'If needed, we send you a swatch.'}
        />

        {/* Step 3 */}
        <HowDoesItWorkCard
          number={'3'}
          image={stepThreeImage}
          text={'We connect you with the supplier.'}
        />

        {/* Step 4 */}
        <HowDoesItWorkCard
          number="4"
          image={stepFourImage}
          text={'The supplier will ship the fabric to your office or directly to the factory.'}
        />
      </Grid>
      <Stack
        direction={'row'}
        justifyContent={'center'}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        <Button href={'/how-it-works'} variant={'outlined'} showArrow>
          Find out more
        </Button>
      </Stack>
    </Box>
  )
}

export default HowDoesItWorkSection
