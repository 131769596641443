// Design system imports
import { Box, Typography, Stack } from '@mui/material'

// Project imports
import Button from '../Input/Button'
import heroBlobs from '../../assets/images/blob.png'

const MissionSection = () => {
  return (
    <Stack component={'section'} justifyContent={'start'} gap={{ xs: 8, md: 0 }} paddingY={8}>
      {/* Heading */}
      <Stack
        direction={{ xs: 'column-reverse', md: 'row' }}
        justifyContent={'space-between'}
        alignItems={'start'}
        gap={{ xs: 4, md: 0 }}
      >
        <Stack justifyContent={'start'} gap={4} width={{ xs: '100%', md: 'calc(50% - 64px)' }}>
          <Typography variant="h4" component="h1" fontWeight={'light'} lineHeight={1.6}>
            We believe that the most sustainable fabric, is the fabric that already exists: recycled
            and dead-stock fabric. There are millions of meters of high-quality fabrics available in
            the world, waiting to be transformed into new products. We are here to connect you, the
            supplier and the user of the circular fabric.
          </Typography>
          <Stack direction={'row'} justifyContent={{ xs: 'center', md: 'start' }}>
            <Button href={'/about-us'} variant={'outlined'} showArrow>
              Read more about our mission
            </Button>
          </Stack>
        </Stack>
        <Box
          component={'img'}
          src={heroBlobs}
          alt={'Fabric Connector logo'}
          width={{ xs: '100%', md: '35%' }}
        />
      </Stack>
    </Stack>
  )
}

export default MissionSection
