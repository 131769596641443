// React imports
import * as React from "react";

// Design system imports
import { Box, CardHeader, Divider } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

// Package imports
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

// Project imports
import { useAppContext } from "../../contexts/AppContext";
import useReducedMotion from "../../hooks/useReducedMotion";

// TODO: Add mutation for instant change

interface ProductCardProps {
  listing: FCProduct;
  hasBeenLiked?: boolean;
}

function ListingCard({ listing }: ProductCardProps) {
  const navigate = useNavigate();
  const { setCurrentProduct } = useAppContext();
  const prefersReducedMotion = useReducedMotion();

  const onSelected = (listing: FCProduct) => {
    setCurrentProduct(listing);
    navigate("/product-page/" + listing.vendor + "/" + listing.id);
  };

  const animationProps = !prefersReducedMotion && {
    whileHover: { scale: 1.025, transition: { duration: 0.4 } },
    whileTap: { y: 2.5, scale: 1, transition: { duration: 0.2 } },
  };

  return (
    <motion.div {...animationProps} style={{width: "100%"}}>
      <Card
        onClick={() => onSelected(listing)}
        elevation={0}
        sx={{
          height: "100%", 
          width: "calc(100% - 4)",
          border: "1px solid #000",
          padding: 2,
          borderRadius: 0,
          display: "flex",
          flexDirection: "column",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            width: "100%",
            paddingTop: "100%",
          }}
        >
          <CardMedia
            component="img"
            width="100%"
            height="100%"
            image={listing.imagesURL[0]}
            alt="product image"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <CardHeader
          title={
            <Box
              sx={{
                width: "100%",
                marginBottom: 1,
                overflow: "hidden",
              }}
            >
              <Typography
                noWrap
                variant="subtitle2"
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >
                {listing.title}
              </Typography>
            </Box>
          }
          subheader={
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Divider
                sx={{
                  borderTop: "1px solid #000",
                  marginLeft: -2,
                  marginRight: -2,
                }}
              />
              <Typography
                noWrap
                variant="caption"
                color="text.secondary"
                sx={{
                  paddingY: 1,
                  textTransform: "uppercase",
                }}
              >
                {listing.options.compositions[0]}
              </Typography>
              <Divider
                sx={{
                  borderTop: "1px solid #000",
                  marginLeft: -2,
                  marginRight: -2,
                }}
              />
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ paddingTop: 1 }}
              >
                €{listing.options.price} / meter
              </Typography>
            </Box>
          }
          sx={{
            width: "100%",
            paddingX: 0,
            paddingBottom: 0,
          }}
        />
      </Card>
    </motion.div>
  );
}

export default React.memo(ListingCard);
