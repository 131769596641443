import { AccountCircle, Bookmark, Favorite, Logout, Mail, ShoppingCart } from '@mui/icons-material'
import MoreVert from '@mui/icons-material/MoreVert'
import SearchIcon from '@mui/icons-material/Search'
import {
  AppBar,
  Badge,
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  Stack,
  Toolbar,
  Tooltip,
} from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Image from 'mui-image'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import logo from '../../assets/logo/logo-black.png'
import logoMobile from '../../assets/logo/logo-mobile-dark.png'
import { useAuth } from '../../contexts/AuthContext'
import LanguageSelect from '../Dropdowns/LanguageSelect'
import SearchSelect from '../Dropdowns/SearchSelect'
import { AddProduct } from '../Forms/AddProduct'
import CreateOrganisation from '../Forms/CreateOrganisation'
import { Search, StyledInputBase } from '../Text/Elements'
import { LinkButtonBlack } from '../LinkButton'
import LinkText from '../LinkText'
// TODO: Translation
// TODO: Add icon

const UserNavbar = () => {
  const { t, i18n } = useTranslation()
  const [addProduct, setAddProduct] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null)

  const navigate = useNavigate()
  const [search, setSearch] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>(search.get('query') ?? '')

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value

    setSearchTerm(text)
  }

  const submit = (event: any) => {
    event.preventDefault()

    if (searchTerm.length == 0) {
      search.delete('query')
      setSearch(search, {
        replace: true,
      })
      navigate(`/search-page`)
    } else {
      search.set('query', searchTerm)
      setSearch(search, {
        replace: true,
      })
      navigate(`/search-page?query=${searchTerm}`)
    }
  }

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }
  const { auth, setAuth, organisation, logout } = useAuth()
  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={() => navigate('my-account')}>
        <ListItemIcon>
          <AccountCircle fontSize="small" />
        </ListItemIcon>
        {t('My account.1')}
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => navigate('my-shop')}>
        <ListItemIcon>
          <ShoppingCart fontSize="small" />
        </ListItemIcon>

        {t('My Shop.1')}
      </MenuItem>

      <MenuItem
        onClick={() => {
          localStorage.removeItem('auth_token')
          setAuth(null)
          navigate('/')
        }}
      >
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        {t('Logout.1')}
      </MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => navigate('my-account')} sx={{ fontWeight: 'bold' }}>
        <ListItemIcon>
          <AccountCircle fontSize="medium" sx={{ color: 'black' }} />
        </ListItemIcon>{' '}
        {t('My account.1')}
      </MenuItem>
      <Divider />
      {organisation !== null ? (
        <MenuItem onClick={() => navigate('my-shop')} sx={{ fontWeight: 'bold' }}>
          <ListItemIcon>
            <ShoppingCart fontSize="medium" sx={{ color: 'black' }} />
          </ListItemIcon>

          {t('My Shop.1')}
        </MenuItem>
      ) : null}
      {organisation !== null ? (
        <MenuItem onClick={() => navigate('my-wishlist')} sx={{ fontWeight: 'bold' }}>
          <ListItemIcon>
            <Bookmark fontSize="medium" sx={{ color: 'black' }} />
          </ListItemIcon>
          {t('My wishlist')}
        </MenuItem>
      ) : null}

      <MenuItem onClick={() => navigate('my-favourites')} sx={{ fontWeight: 'bold' }}>
        <ListItemIcon>
          <Favorite fontSize="medium" sx={{ color: 'black' }} />
        </ListItemIcon>
        {t('My favorites')}
      </MenuItem>

      <MenuItem
        onClick={() => {
          localStorage.removeItem('auth_token')
          setAuth(null)
          navigate('/')
        }}
        sx={{ fontWeight: 'bold' }}
      >
        <ListItemIcon>
          <Logout fontSize="medium" sx={{ color: 'black' }} />
        </ListItemIcon>
        {t('Logout.1')}
      </MenuItem>
      <ListItem>
        {organisation !== null ? (
          <AddProduct title="Add&nbsp;Product" />
        ) : (
          <CreateOrganisation variant="black" />
        )}
      </ListItem>

      {/* <LanguageSelect /> */}
    </Menu>
  )

  return (
    <>
      <AppBar elevation={0} sx={{ borderBottom: '1px solid #dfe0df', py: '2em' }}>
        <Toolbar>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            spacing={2}
            sx={{ width: '100%' }}
          >
            {/* <IconButton
              onClick={() => navigate("/")}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <Image src={icon} height={30} fit="contain" />
            </IconButton> */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Link to="/">
                <Image src={logoMobile} height={40} fit="contain" />
              </Link>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Link to="/">
                <Image src={logo} height={40} fit="contain" />
              </Link>
            </Box>
            <Box>
              <Stack
                sx={{
                  flexGrow: 1,
                  border: '1px solid',
                  borderRadius: '20px',
                  borderColor: 'black',
                  paddingRight: '0.5em',
                }}
                justifyContent="center"
                alignItems="center"
                direction="row"
                spacing={0.1}
              >
                <form onSubmit={submit}>
                  <Search direction="row-reverse" sx={{ padding: '10px' }}>
                    <IconButton type="submit">
                      <SearchIcon
                        color="inherit"
                        sx={{ stroke: 'black', strokeWidth: 1, color: 'black' }}
                      />
                    </IconButton>

                    <StyledInputBase
                      onChange={onSearchChange}
                      placeholder={t('Search products')}
                      inputProps={{ 'aria-label': 'search' }}
                      sx={{
                        '& input::placeholder': {
                          color: 'black', // Change the placeholder color here
                          opacity: 1, // Optional: set opacity to 1 to ensure full visibility
                        },
                      }}
                    />
                  </Search>
                </form>
              </Stack>
            </Box>

            <Stack
              justifyContent="center"
              alignItems="center"
              direction="row"
              spacing={2}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              {organisation !== null ? (
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                  onClick={() => navigate('notifications')}
                >
                  <Badge color="error">
                    <Mail sx={{ color: 'neutral.main' }} />
                  </Badge>
                </IconButton>
              ) : null}

              <IconButton color="inherit" onClick={() => navigate('my-favourites')}>
                <Favorite sx={{ color: 'black', fontSize: 35 }} />
              </IconButton>
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                sx={{
                  borderColor: 'black',
                  height: '45px',
                  alignSelf: 'center',
                  borderWidth: '1px',
                  backgroundColor: 'black',
                }}
              />
              {/* {organisation !== null ? (
                <IconButton
                  color="inherit"
                  onClick={() => navigate("my-wishlist")}
                >
                  <Bookmark sx={{ color: "neutral.main" }} />
                </IconButton>
              ) : null} */}

              <Tooltip title="Account settings">
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleProfileMenuOpen}
                >
                  <AccountCircle sx={{ color: 'black', fontSize: 35 }} />
                </IconButton>
              </Tooltip>
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                sx={{
                  borderColor: 'black',
                  height: '45px',
                  alignSelf: 'center',
                  borderWidth: '1px',
                  backgroundColor: 'black',
                }}
              />
              <Stack direction="row" spacing={1} alignItems={'center'}>
                <LinkText sx={{ fontSize: 20 }} href="/about-us">
                  About us
                </LinkText>

                {/* Add product form dialog */}
                {organisation !== null ? (
                  <AddProduct title="Add&nbsp;Product" />
                ) : (
                  <CreateOrganisation variant="black" />
                )}
              </Stack>
            </Stack>
            <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                color: 'primary.main',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreVert sx={{ fontSize: 35, color: 'black' }} />
              </IconButton>
            </Box>
            {renderMenu}
            {renderMobileMenu}
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default UserNavbar
