// Design system imports
import { Box, Stack, Typography } from '@mui/material'

// Project imports
import NewestGallery from '../Gallery/NewestGallery'
import Button from '../Input/Button'

const FabricsSection = () => {
  return (
    <Box component={'section'} paddingY={8}>
      <Stack gap={4}>
        <Typography variant="h4" component="h2" fontWeight={'bold'} lineHeight={1.6}>
          Browse through our selection of circular fabrics
        </Typography>
        <Stack alignItems={'center'} gap={4}>
          <Box width={'100%'} height={'100%'}>
            <NewestGallery />
          </Box>
          <Typography variant={'h5'} component={'h3'} lineHeight={1.6}>
            We have more in stock!
          </Typography>
          <Button href={'/search-page'} variant={'outlined'} showArrow>
            Show me more
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default FabricsSection
