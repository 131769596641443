import { PlaylistAdd } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field } from "formik";
import { Select, TextField } from "formik-mui";
import { FormikHelpers, FormikStep, FormikStepper } from "formik-stepper";
import "formik-stepper/dist/style.css";
import { DropzoneDialog } from "mui-file-dropzone";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { addListing } from "../../api/listingsApi";
import { useAuth } from "../../contexts/AuthContext";
import {
  categoriesList,
  colorsList,
  compositionList,
  countries,
  currencies,
  designsList,
  fiberTypes,
  materialsList,
  patternList,
  usageList,
} from "../../utils/FilterEngine";
import { ProductFormSchema } from "../schemas";
import UploadStepper from "../UploadStepper";
import { resizeFile } from "../../utils/Uploading";
import { MAX_PRODUCT_IMAGE_SIZE } from "../constants";
import AdvancedDropzoneDemo from "../Dropzone/BasicDropzone";
import UploadDialog from "../Dialog/UploadDialog";
// TODO: Refactor
// TODO: Add country code dropdown
interface Props {
  title: string;
}
export const AddProduct = ({ title }: Props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openStepper, setOpenStepper] = useState(false);
  const getTutorialSeen = () => localStorage.getItem("tutorial");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const queryClient = useQueryClient();

  const handleClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason === "backdropClick") {
    } else {
      setOpen(false);
    }
  };

  const handleClickOpenStepper = () => {
    setOpen(true);
  };

  const handleCloseStepper = () => {
    setOpenStepper(false);
  };
  const [images, setImages] = useState<File[]>([]);
  const [initialImages, setInitialImages] = useState<File[]>([]);
  const [image, setImage] = useState<string | undefined>("");
  const [materials, setMaterials] = useState<string[]>([]);
  const [b64Images, setB64Images] = useState<FCImage[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { auth, organisation, globalFilters } = useAuth();

  const updateImages = (incommingFiles: any) => {
    setB64Images(incommingFiles);
  };
  const addProductMutation = useMutation(addListing, {
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("myProducts");
      queryClient.invalidateQueries("newestProducts");
      enqueueSnackbar("Succesfully added product", {
        variant: "success",
        preventDuplicate: true,
      });
      setB64Images([]);
      setInitialImages([]);
      setOpen(false);
    },
    onError: (err: any) => {
      if (err.response.status === 403) {
        enqueueSnackbar(err.response.data.message, {
          variant: "info",
          preventDuplicate: true,
        });
        setTimeout(() => {
          window.open("/my-membership");
        }, 2000);
      } else {
        enqueueSnackbar("Unsuccesfully added product", {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
  });

  const onSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    const data = {
      title: values.title,
      description: values.description,
      category: values.category,
      options: {
        colors: values.colors,
        patterns: values.patterns,
        length: values.length,
        weight: values.weight,
        price: values.price,
        width: values.width,
        quantity: 0,
        countryCode: values.countryCode ?? "NL",
        currency: values.currency,
        fibers: values.fibers,
        compositions: values.compositions,
        materials: values.materials,
        usages: values.usages,
        designs: values.designs,
      },
      images: b64Images,
    };

    addProductMutation.mutate({ organisation: organisation, listing: data });
  };
  const convert2base64 = async (files: any) => {
    console.log(files);
    try {
      for (let i = 0; i < files.length; i++) {
        console.log(files[i]);
        const image = await resizeFile(files[i]);
        console.log(image);
        b64Images.push({
          fileContentBase64: image!,
          fileName: `image ${i}`,
        });
      }
    } catch (error) {}
  };
  // useEffect(() => {
  //   console.log("changed");
  //   console.log(b64Images);
  // }, [b64Images]);

  return (
    <>
      <ButtonGroup
        variant="contained"
        sx={{ maxHeight: 45 }}
        fullWidth
        color="primary"
      >
        <Button
          // size="medium"
          onClick={handleClickOpen}
          sx={{ color: "white" }}
        >
          {title}
        </Button>
        <Tooltip title="Bulk Add">
          <Button
            size="small"
            sx={{ maxWidth: 30, color: "white" }}
            onClick={() => navigate("/bulk-update")}
          >
            <PlaylistAdd color="inherit" />
          </Button>
        </Tooltip>
      </ButtonGroup>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogContent>
          <Button
            sx={{ maxWidth: 200 }}
            variant="contained"
            size="medium"
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Exit
          </Button>
          <Card sx={{ maxWidth: 2000 }} elevation={0}>
            <CardContent>
              <FormikStepper
                /// Accept all Formik props
                onSubmit={onSubmit} /// onSubmit Function
                validationSchema={ProductFormSchema}
                initialValues={{
                  title: "",
                  description: "",
                  category: "",
                  colors: [],
                  materials: [],
                  currency: "EUR",
                  length: 0,
                  weight: 0,
                  price: 0,
                  width: 0,
                  patterns: [],
                  compositions: [],
                  countryCode: "",
                  fibers: [],
                  usages: [],
                  designs: [],
                }}
                nextButton={{
                  label: "Next Step",
                  style: { background: "#cf6631" },
                }}
                prevButton={{ label: "Back", style: { background: "#cf6631" } }}
                submitButton={{
                  label: "Upload",
                  style: { background: "blue" },
                }}
              >
                {/*  First Step */}
                <FormikStep label="Add product" circleColor="#6f42c1">
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Typography>Title*</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Field
                    name="title"
                    component={TextField}
                    multiline
                    rows={4}
                    fullWidth
                    helperText="For example: 60m of Silk fabric"
                  />
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Typography>Describe your product*</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Field
                    name="description"
                    component={TextField}
                    multiline
                    rows={4}
                    fullWidth
                    helperText="For example: Left-over, new fabric, pink silk fabric"
                  />
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true, required: true }}
                    component={Select}
                    formHelperText={{ children: "Select 1 or more colors" }}
                    multiple
                    name="colors"
                    labelId="color-simple"
                    label="Color"
                  >
                    {globalFilters!.colors.map((color: any) => (
                      <MenuItem key={color} value={color}>
                        {color}
                      </MenuItem>
                    ))}
                  </Field>

                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true, required: true }}
                    formHelperText={{
                      children: "Select 1 or more materials",
                    }}
                    component={Select}
                    multiple
                    id="materials"
                    name="materials"
                    labelId="materials-simple"
                    label="Materials"
                  >
                    {globalFilters!.materials.map((material: any) => (
                      <MenuItem key={material} value={material}>
                        {material}
                      </MenuItem>
                    ))}
                  </Field>

                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true }}
                    component={Select}
                    id="category"
                    name="category"
                    label={"Category*"}
                  >
                    {globalFilters!.categories.map((category: any) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Field>
                </FormikStep>
                {/* Second Step */}
                <FormikStep
                  label="Additional information"
                  circleColor="#6f42c1"
                >
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Typography textAlign="center">
                    Add a Maximum of 5 photos
                  </Typography>
                  <Divider sx={{ my: 2 }} />

                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Typography>Description of fabric contents</Typography>
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true, required: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select fiber type(s) used in your fabric",
                    }}
                    multiple
                    name="fibers"
                    id="fibers"
                    labelId="fiber-simple"
                    label="Fiber"
                  >
                    {globalFilters!.fibers.map((fiber: any) => (
                      <MenuItem key={fiber} value={fiber}>
                        {fiber}
                      </MenuItem>
                    ))}
                  </Field>
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true, required: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select usage(s) of your fabric",
                    }}
                    name="usages"
                    multiple
                    id="usages"
                    labelId="usage-simple"
                    label="Usage"
                  >
                    {globalFilters!.usages.map((use: any) => (
                      <MenuItem key={use} value={use}>
                        {use}
                      </MenuItem>
                    ))}
                  </Field>

                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true, required: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select composition(s) used in your fabric",
                    }}
                    name="compositions"
                    id="compositions"
                    multiple
                    labelId="composition-simple"
                    label="Composition"
                  >
                    {globalFilters!.compositions.map((composition: any) => (
                      <MenuItem key={composition} value={composition}>
                        {composition}
                      </MenuItem>
                    ))}
                  </Field>

                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select design(s) found in your fabric",
                    }}
                    name="designs"
                    id="designs"
                    multiple
                    labelId="designs-simple"
                    label="Design"
                  >
                    {globalFilters!.designs.map((design: any) => (
                      <MenuItem key={design} value={design}>
                        {design}
                      </MenuItem>
                    ))}
                  </Field>
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select pattern(s) found in your fabric",
                    }}
                    multiple
                    id="patterns"
                    name="patterns"
                    labelId="colour-simple"
                    label="Pattern"
                  >
                    {globalFilters!.patterns.map((pattern: any) => (
                      <MenuItem key={pattern} value={pattern}>
                        {pattern}
                      </MenuItem>
                    ))}
                  </Field>
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    formControl={{ fullWidth: true, required: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select the country of origin of your fabric",
                    }}
                    id="countryCode"
                    name="countryCode"
                    labelId="colour-simple"
                    label="Country of Origin"
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.label} value={country.value}>
                        {country.label}
                      </MenuItem>
                    ))}
                  </Field>

                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    name="length"
                    component={TextField}
                    type="number"
                    fullWidth
                    helperText="Select a length in meters"
                  />
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    name="weight"
                    component={TextField}
                    type="number"
                    fullWidth
                    helperText="Select a weight in g/m2"
                  />
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    name="width"
                    component={TextField}
                    type="number"
                    fullWidth
                    helperText="Select a width in centimeters"
                  />
                  <Divider sx={{ my: 2 }} />
                  {/* TODO:Translation/code */}
                  <Field
                    name="price"
                    component={TextField}
                    type="number"
                    fullWidth
                    helperText="Specify the price per meter"
                  />
                  <Divider sx={{ my: 2 }} />
                  <Field
                    formControl={{ fullWidth: true, required: true }}
                    component={Select}
                    formHelperText={{
                      children: "Select currency",
                    }}
                    id="currency"
                    name="currency"
                    labelId="currency"
                    label="Currency"
                  >
                    {currencies.map((currency) => (
                      <MenuItem key={currency.value} value={currency.value}>
                        {currency.label}
                      </MenuItem>
                    ))}
                  </Field>

                  <Divider sx={{ my: 2 }} />

                  <Stack
                    direction="row"
                    sx={{ width: "100%" }}
                    justifyContent="space-around"
                  >
                    <Stack>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setB64Images([]);
                          setInitialImages([]);
                          setOpenUpload(true);
                          getTutorialSeen();
                          if (getTutorialSeen() === null) {
                            setTimeout(() => {
                              setOpenStepper(true);
                            }, 100);
                            localStorage.setItem("tutorial", "true");
                          }
                        }}
                      >
                        Upload Photos
                      </Button>
                      <Typography>{b64Images.length} files added</Typography>
                    </Stack>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setOpenStepper(true);
                      }}
                    >
                      Need help?
                    </Button>
                  </Stack>
                </FormikStep>
              </FormikStepper>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
      <UploadStepper onClose={handleCloseStepper} open={openStepper} />
      <UploadDialog
        openUpload={openUpload}
        setB64Images={setB64Images}
        setOpenUpload={setOpenUpload}
      />
    </>
  );
};
