// React imports
import React from 'react'
import { ReactNode } from 'react'

// Design system imports
import { Stack, Typography } from '@mui/material'

const BannerSection = ({ text, children }: { text: string; children?: ReactNode }) => {
  return (
    <Stack component={'section'} paddingY={8}>
      <Typography
        variant={'h4'}
        component={'h2'}
        fontWeight={'bold'}
        textAlign={'center'}
        lineHeight={1.6}
      >
        {text.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </Typography>
      {children && <Stack paddingTop={8}>{children}</Stack>}
    </Stack>
  )
}

export default BannerSection
