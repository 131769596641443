// Design system imports
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import styled from '@mui/material/styles/styled'
import { motion } from 'framer-motion'
import useReducedMotion from '../../hooks/useReducedMotion'
import { theme } from '../../theme'

// Types
interface ButtonProps extends MuiButtonProps {
  showArrow?: boolean
  href?: string
}

// Styles
const StyledButton = styled(MuiButton)(({ theme, size }) => ({
  width: 'fit-content',
  padding: `${size === 'small' ? '8px 16px' : size === 'medium' ? '12px 24px' : '16px 32px'}`,
  borderRadius: 50,
  textTransform: 'none',
  '&.MuiButton-outlined': {
    borderWidth: 1,
    borderColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      borderWidth: 1,
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
  },
  '&.MuiButton-contained': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  '&.MuiButton-text': {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.error.main,
      backgroundColor: 'transparent',
    },
  },
}))

// Component
const Button: React.FC<ButtonProps> = ({
  showArrow,
  children,
  href,
  size = 'medium',
  ...props
}) => {
  const prefersReducedMotion = useReducedMotion()
  const commonAnimationProps = prefersReducedMotion
    ? {
        whileHover: {
          opacity: 0.8,
          transition: { duration: 0.2 },
        },
        whileTap: { opacity: 0.6, transition: { duration: 0.2 } },
      }
    : {
        whileHover: { scale: 1.1, transition: { duration: 0.4 } },
        whileTap: { y: 2.5, scale: 0.9, transition: { duration: 0.2 } },
      }

  return (
    <motion.div {...commonAnimationProps} style={{ width: 'fit-content' }}>
      <StyledButton href={href} size={size} {...props}>
        {children}
        {showArrow && <ArrowForwardIosIcon sx={{ ml: 1, fontSize: 10 }} />}
      </StyledButton>
    </motion.div>
  )
}

export default Button
