import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#000",
        contrastText: "#fff",
      },
      secondary: {
        main: "#fff",
        contrastText: "#000",
      },
      neutral: {
        main: "#d9d9d9",
        contrastText: "#fff",
      },
      error: {
        main: "#f15822",
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
      fontFamily: [
        "Poppins",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      h1: {
        fontFamily: "Poppins",
        fontWeight: 700, // H900
      },
      h2: {
        fontFamily: "Poppins",
        fontWeight: 400, // H800
      },
      h3: {
        fontFamily: "Poppins",
        fontWeight: 500, // H700
      },
      h4: {
        fontFamily: "Poppins",
        fontWeight: 400, // H600
      },
      h5: {
        fontFamily: "Poppins",
        fontWeight: 400, // H500
      },
      h6: {
        fontFamily: "Poppins",
        fontWeight: 400, // H400
      },
      body1: {
        fontFamily: "Poppins",
        fontWeight: 400, // Regular
      },
      body2: {
        fontFamily: "Poppins",
        fontWeight: 400, // Light
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: "white",
          },
        },
      },
    },
  })
);

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}
