// Design system imports
import { Box, Grid, Typography } from "@mui/material";

const HowDoesItWorkCard = ({
  number,
  image,
  text,
}: {
  number: string;
  image: string;
  text: string;
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      sx={{ margin: { xs: 4, sm: 0 } }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          variant="h4"
          component="h3"
          sx={{ fontWeight: "bold" }}
        >
          {number}
        </Typography>
        <Box
          component={"img"}
          src={image}
          alt="Find a fabric"
          sx={{ width: "75%", height: "auto", padding: 4 }}
        />
        <Typography
          variant="body1"
          component="h3"
        >
          {text}
        </Typography>
      </Box>
    </Grid>
  );
};

export default HowDoesItWorkCard;
