// React imports
import { useEffect, useState } from 'react'

// Design system imports
import { Box, Stack } from '@mui/material'

// Package imports
import { useSnackbar } from 'notistack'

// Project imports
import { useAuth } from '../contexts/AuthContext'
import Button from '../components/Input/Button'
import ClientSection from '../components/Sections/ClientSection'
import FabricsSection from '../components/Sections/FabricsSection'
import HeroSection from '../components/Sections/HeroSection'
import HowDoesItWorkSection from '../components/Sections/HowDoesItWorkSection'
import BannerSection from '../components/Sections/BannerSection'
import MissionSection from '../components/Sections/MissionSection'
import FullScreenDivider from '../components/FullScreenDivider'
import { theme } from '../theme'

const Homepage = () => {
  const { auth } = useAuth()
  const [, setIsLoggedin] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  // Check if user is logged in
  useEffect(() => {
    auth?.name ? setIsLoggedin(true) : setIsLoggedin(false)
  }, [auth, enqueueSnackbar])

  return (
    <Box width={'100%'} height={'100%'} minHeight={'100vh'}>
      <HeroSection />
      <FullScreenDivider color={theme.palette.primary.main} />
      <BannerSection
        text={
          '“There are millions of meters of high quality surplus fabrics available in the world, waiting to be transformed into new products. We have access to some of them.”'
        }
      />
      <FullScreenDivider color={theme.palette.primary.main} />
      <HowDoesItWorkSection />
      <FullScreenDivider color={theme.palette.primary.main} />

      <FabricsSection />
      <FullScreenDivider color={theme.palette.primary.main} />
      <BannerSection
        text={
          "Are you supplier offering dead stock or recycled fabric?\nWe'd love to get in contact."
        }
      >
        <Stack direction={'row'} justifyContent={'center'}>
          <Button href="/contact" variant="outlined" showArrow>
            Contact us
          </Button>
        </Stack>
      </BannerSection>
      <FullScreenDivider color={theme.palette.primary.main} />
      <MissionSection />
      <FullScreenDivider color={theme.palette.primary.main} />
      <BannerSection
        text={
          'Are you interested to see how your company can become more circular? We are happy to advice.'
        }
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={'center'}
          alignItems={'center'}
          gap={4}
        >
          <Button variant={'outlined'} href={'/contact'} showArrow>
            Contact us
          </Button>
          <Button variant={'outlined'} href={'/search-page'} showArrow>
            Circular material store
          </Button>
        </Stack>
      </BannerSection>
      <FullScreenDivider color={theme.palette.primary.main} />
      <ClientSection />
    </Box>
  )
}

export default Homepage
