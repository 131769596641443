// React imports
import { useEffect, useState } from "react";

//Design system imports
import { Box, Grid } from "@mui/material";

// Package imports
import { useQuery, useQueryClient } from "react-query";

// Project imports
import axiosClient from "../../api/axiosClient";
import { getNewestListings } from "../../api/listingsApi";
import { useAuth } from "../../contexts/AuthContext";
import ListingCard from "../Cards/ListingCard";
import NoProducts from "../Error/NoProducts";
import Loading from "../Loading";

const NewestGallery = () => {
  const queryClient = useQueryClient();
  const [likedList, setLikedList] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const { auth } = useAuth();

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const {
    isLoading,

    status,
    error,
    data: neweProducts,
    isFetching,
    isPreviousData,
  } = useQuery(["newestProducts", page], () => getNewestListings(page - 1), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  function getFavoritedProducts() {
    return axiosClient
      .get(`api/user/current/like_ids`)
      .then((res: any) => {
        let likedIDs = res.data;
        setLikedList(likedIDs);
        // Check if product has been liked before
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if (!!auth) getFavoritedProducts();
  }, [isFetching, isLoading]);

  //  fixme
  return (
    <Grid
      container
      spacing={3}
      sx={{ width: "calc(100% + 3)", height: "100%", ml:  -3,  mr: -3 }}
      justifyContent="flex-start"
      alignItems="center"
    >
      {isLoading || isFetching ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            width: "100%",
          }}
        >
          <Loading />
        </Box>
      ) : status === "error" ? (
        <div>Unable to fetch products. Please try again</div>
      ) : neweProducts!.content.length !== 0 ? (
        neweProducts!.content.map((product, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={i}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <ListingCard listing={product} />
            </Grid>
          );
        })
      ) : (
        <NoProducts />
      )}
      <Grid
        item
        xs={12}
      ></Grid>
    </Grid>
  );
};

export default NewestGallery;
