import { Rating, Stack } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import { alpha, styled } from "@mui/material/styles";

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const Search = styled(Stack)(({ theme }) => ({
  // borderRadius: "0px 4px 4px 0px",
  // backgroundColor: alpha(theme.palette.common.white, 1),
  // "&:hover": {
  //   backgroundColor: alpha(theme.palette.common.white, 0.25),
  // },

  marginLeft: 0,
  width: "100%",
  color: "black",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.027, 1.1, 1.1, 1.1),

    // vertical padding + font size from searchIcon
    fontWeight: "bold",
    transition: theme.transitions.create("width"),
    width: "100%",

    [theme.breakpoints.up("xl")]: {
      width: "35vw",
    },
  },
}));

export const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#4d4b4b",
  },
  "& .MuiRating-iconHover": {
    color: "#cf6631",
  },
});
